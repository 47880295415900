/* 外部方法 */
import { onMounted, onBeforeUnmount, ref, computed } from 'vue';
import { cloneDeep } from 'lodash-es';
/* 內部方法 */
import { pinia } from '@/store';
import useModal from '@/composables/useModal';
import useI18n from '@sms/common/composables/useI18n';

/* 型別 */
import type { IpcRendererEvent } from 'electron';
import type CheckerStage from '@sms/common/interfaces/CheckerStage';

/** 系統元件 */
const { t } = useI18n();
const { modalOk } = useModal(pinia);

export default function useElectronChecker() {
  const BLANKLIST = {
    poker: false,
    hand: false,
    dice: false,
    roulette: false,
    calibration: false,
    coordination: false,
    ffmpeg: false
  };

  const checkList = ref<Record<CheckerStage, boolean>>(cloneDeep(BLANKLIST));

  const isChecking = ref(false);

  const isCheckFinish = ref(false);

  const checkCount = ref(0);

  const checkStage = ref<CheckerStage>('poker');

  const checkingProgress = computed(() =>
    (100 - (checkCount.value / Object.keys(checkList.value).length) * 100).toFixed(2)
  );

  const handleCheckerStart = () => {
    checkStage.value = 'poker';
    checkCount.value = 0;
    checkList.value = cloneDeep(BLANKLIST);
    isChecking.value = true;
    isCheckFinish.value = false;
  };

  const handleCheckProgress = (event: IpcRendererEvent, stage: CheckerStage) => {
    isChecking.value = true;
    checkCount.value += 1;
    checkStage.value = stage;
  };

  const handkeCheckResult = (event: IpcRendererEvent, res: { stage: CheckerStage; result: boolean }) => {
    checkList.value[res.stage] = res.result;
  };

  const handleCheckerFinish = () => {
    modalOk({
      title: t('Dealer.CheckFinish')
    });

    isChecking.value = false;
    isCheckFinish.value = true;
  };

  const appCheck = () => {
    if (window.startChecker) window.startChecker();
  };

  onMounted(async () => {
    if (!window.ipcRendererService) return console.log('ipcRendererService is undefined');

    window.ipcRendererService.on('start-checker', handleCheckerStart);
    window.ipcRendererService.on('finish-checker', handleCheckerFinish);
    window.ipcRendererService.on('check-stage', handleCheckProgress);
    window.ipcRendererService.on('check-result', handkeCheckResult);
  });

  onBeforeUnmount(() => {
    if (!window.ipcRendererService) return;

    window.ipcRendererService.off('start-checker', handleCheckerStart);
    window.ipcRendererService.off('finish-checker', handleCheckerFinish);
    window.ipcRendererService.off('check-stage', handleCheckProgress);
    window.ipcRendererService.off('check-result', handkeCheckResult);
  });

  return {
    checkList,
    isChecking,
    isCheckFinish,
    checkingProgress,
    checkCount,
    checkStage,
    appCheck
  };
}
