/* 外部方法 */
import { DateTime } from 'luxon';
import { onBeforeUnmount, ref } from 'vue';

const timeFuncs = {
  setTimeout: {
    tick: (fn: () => void) => window.setTimeout(fn, 200),
    clearTick: window.clearTimeout
  },
  raf: {
    tick: requestAnimationFrame,
    clearTick: cancelAnimationFrame
  }
};

const defaults = {
  spreator: '-',
  timeZone: 'local',
  timeFunc: 'setTimeout' as keyof typeof timeFuncs
};

export default function useClock(options = {} as Partial<typeof defaults>) {
  const { spreator, timeFunc, timeZone } = { ...defaults, ...options };
  const { tick, clearTick } = timeFuncs[timeFunc];

  const date = ref('');
  const time = ref('');
  const offsetText = ref('');

  let timer = 0;

  const tickTock = () => {
    const now = DateTime.now().setZone(timeZone);

    date.value = now.toFormat(`yyyy${spreator}MM${spreator}dd`);
    time.value = now.toFormat('HH:mm:ss');

    // 偏移量文字處理
    function splitOffsetString(timeOffset: string) {
      const match = timeOffset.match(/^([+-])(\d+:\d+)$/); // 正則表達式匹配符號和時間
      return match ? [match[1], match[2]] : null; // 返回符號和時間，或返回 null
    }

    const offsetStringSplited = splitOffsetString(now.toFormat('ZZ'));

    if (offsetStringSplited?.length) {
      const [symbol, offsetTime] = offsetStringSplited;
      const [offsetHour, offsetMin] = offsetTime.split(':');

      const isUTC = offsetHour === '00' && offsetMin === '00';
      const offsetHourFormat = parseInt(offsetHour, 10).toString();
      const offsetMinFormat = parseInt(offsetMin, 10).toString();

      offsetText.value = isUTC
        ? 'UTC+0'
        : offsetMinFormat === '0'
        ? `UTC${symbol}${offsetHourFormat}`
        : `UTC${symbol}${offsetHourFormat}:${offsetMinFormat}`;
    }

    timer = tick(tickTock);
  };

  tickTock();
  onBeforeUnmount(() => clearTick(timer));

  return {
    date,
    time,
    offsetText
  };
}
