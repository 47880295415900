/* 外部方法 */
import { createRouter, createWebHashHistory } from 'vue-router';

/* 內部組件 */
import Login from '@/views/Login.vue';
import DeskLogin from '@/views/DeskLogin.vue';
import AppDownload from '@/views/AppDownload/AppDownload.vue';

/* 型別 */
import type { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/desk/:deskId',
    name: 'Desk',
    component: () => import('@/views/Desk/DeskIndex.vue'),
    props: true,
    children: [
      {
        path: 'bac',
        name: 'BAC',
        component: () => import('@/views/Desk/BAC/BACIndex.vue'),
        props: true
      },
      {
        path: 'drt',
        name: 'DRT',
        component: () => import('@/views/Desk/DRT/DRTIndex.vue'),
        props: true
      },
      {
        path: 'bjb',
        name: 'BJB',
        component: () => import('@/views/Desk/BJB/BJBIndex.vue'),
        props: true
      },
      {
        path: 'sib',
        name: 'SIB',
        component: () => import('@/views/Desk/SIB/SIBIndex.vue'),
        props: true
      },
      {
        path: 'rou',
        name: 'ROU',
        component: () => import('@/views/Desk/ROU/ROUIndex.vue'),
        props: true
      },
      {
        path: 'sed',
        name: 'SED',
        component: () => import('@/views/Desk/SED/SEDIndex.vue'),
        props: true
      },
      {
        path: 'fan',
        name: 'FAN',
        component: () => import('@/views/Desk/FAN/FANIndex.vue'),
        props: true
      },
      {
        path: 'mbl',
        name: 'MBL',
        component: () => import('@/views/Desk/MBL/MBLIndex.vue'),
        props: true
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/desk-login',
    name: 'DeskLogin',
    component: DeskLogin
  },
  {
    path: '/app-download',
    name: 'AppDownload',
    component: AppDownload
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Login' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
