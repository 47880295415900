<template>
  <div class="flex flex-col items-center">
    <div class="fixed top-0 left-0 p-6">
      <SBtn
        size="md"
        :icon="mdiCheckboxMultipleOutline"
        class="h-min w-min !rounded-lg text-white bg-orange-400 text-sm font-bold btn flex gap-1 py-2 px-4 mb-2"
        @click="appCheck"
      >
        <span class="whitespace-nowrap">{{ t('Dealer.CheckDependency') }}</span>
      </SBtn>

      <div class="flex flex-col" v-if="isChecking || isCheckFinish">
        <div class="flex gap-1 justify-between items-center pl-2 pr-1" v-for="(value, stage) in checkList" :key="stage">
          <span>{{ checkListDesc(stage) }}</span>
          <SIcon :icon="mdiCheck" class="w-6 h-6 text-green-600" v-if="value" />
          <SIcon :icon="mdiClose" class="w-6 h-6 text-red-600" v-else />
        </div>
      </div>
    </div>
    <template v-if="showProgress">
      <!-- Downloading Progress -->
      <div class="progress flex items-center justify-center max-w-2xl h-5 rounded-[10px] px-1 border mb-3 w-[470px]">
        <div class="relative w-full h-[10px] rounded-[10px] bg-white bg-opacity-[0.15] overflow-hidden">
          <div>
            <div class="progress__indeterminate" :style="`right:${progress}%;`" />
          </div>
        </div>
      </div>
      <div class="text-orange-400 text-xl">
        {{ progressStage }}
      </div>
    </template>

    <template v-else>
      <div class="flex flex-col items-center" v-if="updateAvailable">
        <SBtn
          size="md"
          :icon="mdiFolderDownload"
          class="h-[55px] w-[270px] !rounded-lg text-white bg-orange-400 text-2xl font-bold btn flex gap-4 px-4 mb-6"
          @click="appUpdate"
        >
          <span>{{ t('Dealer.UpdateSystem') }}</span>
        </SBtn>

        <span class="text-red-400 text-xl deco" v-if="isDownloadError">
          {{ t('Dealer.UpdateError') }}
        </span>
        <span class="text-orange-400 text-xl" v-else>{{ t('Dealer.UpdateButtonDescription') }}</span>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
/* 內部方法 */
import useI18n from '@sms/common/composables/useI18n';
import useElectronUpdate from '@sms/common/composables/useElectronUpdate';
import useElectronChecker from '@/composables/useElectronChecker';

/* 外部組件 */
import { mdiFolderDownload, mdiCheckboxMultipleOutline, mdiCheck, mdiClose } from '@mdi/js';

/* 內部組件 */
import SBtn from '@sms/components/SBtn/SBtn.vue';
import SIcon from '@sms/components/SIcon/SIcon.vue';

defineEmits(['updateApp']);

/** 系統元件 */
const { t } = useI18n();

const { appState, isDownloading, updateProgress, isDownloadError, appUpdate } = useElectronUpdate();
const { checkList, isChecking, isCheckFinish, checkingProgress, checkStage, appCheck } = useElectronChecker();

const showProgress = computed(() => isChecking.value || (isDownloading.value && !isDownloadError.value));

const progress = computed(() => {
  if (isChecking.value) {
    return checkingProgress.value;
  }
  if (isDownloading.value) {
    return updateProgress.value;
  }
  return '0';
});

const updateAvailable = computed(() => appState.value.update.updateAvailable);

const progressStage = computed(() => {
  if (isDownloading.value) {
    return progress.value === '100' ? t('Dealer.DownloadFinish') : t('Dealer.Downloading');
  }

  let stage = '';

  switch (checkStage.value) {
    case 'poker':
    case 'dice':
    case 'roulette':
    case 'hand':
      stage = t(`Dealer.PredictionChecking`);
      break;
    case 'calibration':
    case 'coordination':
      stage = t(`Dealer.ColorCorrectionChecking`);
      break;
    case 'ffmpeg':
      stage = t(`Dealer.FfmpegChecking`);
      break;
  }

  return progress.value === '100' ? t('Dealer.CheckFinish') : stage;
});

const checkListDesc = (stage: keyof typeof checkList.value) => {
  switch (stage) {
    case 'poker':
    case 'dice':
    case 'roulette':
    case 'hand':
      return `${stage} prediction`;
    case 'calibration':
    case 'coordination':
      return `color ${stage}`;
    case 'ffmpeg':
      return `ffmpeg`;
  }
};
</script>

<style scoped>
.progress {
  border-color: rgba(255, 255, 255, 0.25);
}
.progress__indeterminate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  background: linear-gradient(269.67deg, #fff7ed -49.96%, #fed7aa 5.65%, #fb923c 100.61%);
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.2);
  height: inherit;
  animation-play-state: running;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  border-radius: 10px;
  transition: right 1s ease-in-out;
}
</style>
