/* 外部方法 */
import { cloneDeep } from 'lodash-es';

/* 內部方法 */
import SourceLoader from '../SourceLoader';

/* 內部組件 */
import bankerWinSvg from './assets/bigRoad-banker.svg';
import playerWinSvg from './assets/bigRoad-player.svg';
import tieSvg from './assets/bigRoad-tie.svg';
import tiesSvg from './assets/bigRoad-ties.svg';

/* 型別 */
import type GameResultSet from '../../models/GameResultSet';
import type WinFlagMapping from '../../interfaces/WinFlagMapping';

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d', { willReadFrequently: true });

enum WinFlag {
  TIE = 'T',
  MULTI_TIE = 'TT'
}

/* 使用 sourceloader 確保資源預先載入  */
export const roadmapLoader = new SourceLoader(import.meta.globEager('./assets/*.svg'));

/** 根據 svg 產生圖片元素以供 canvas.drawImage() 函式使用  */
const createImage = (svgPath: string, w = 50, h = 50) => {
  const image = new Image(w, h);
  image.src = svgPath;
  return image;
};

const BANKER_WIN = createImage(bankerWinSvg);
const PLAYER_WIN = createImage(playerWinSvg);
const TIE = createImage(tieSvg);
const TIES = createImage(tiesSvg);

// 路圖設定

/*
 * 有點難記所以畫個圖
 *
 * 直行 COLUMN 橫列 ROW
 *
 *       行 COLUMN 行 COLUMN 行 COLUMN
 * 列 ROW
 * 列 ROW
 * 列 ROW
 *
 */

export interface RoadmapSettings {
  columns: number;
  rows: number;
  canvasWidth: number;
  canvasHeight: number;
  lineWidth: number;
  lineColor: string;
  cellPadding: number;
}

/** 將遊戲結果轉換為陣列路圖 */
const createArrayOfRoad = (
  gameResultSets: GameResultSet[],
  { Player, Banker }: WinFlagMapping,
  { columns: COLUMNS, rows: ROWS }: RoadmapSettings
) => {
  /* 根據欄數產生二維路圖陣列 */
  const arrayOfRoad: string[][] = Array.from({ length: ROWS }, () => []);
  let x = 0;
  let y = 0;
  let isDragon = false;
  let xOfDragonHead = 0;
  let xOfDragonTail = 0;

  /**
   * 將二維路圖陣列長度對齊並將 empty 取代為 undefined
   *
   * @param array 二維字串陣列
   * @param slice 由後向前取切割長度
   */
  const extendArrayOfRoad = () => {
    let array = cloneDeep(arrayOfRoad);

    /* 取得最長的列 */
    const maxLength = Math.max(...array.map((row) => row.length + 1), COLUMNS);

    array.forEach((row) => {
      Object.assign(row, { length: maxLength });
    });

    array = array.map((row) => row.map((cell) => (cell ? cell.padEnd(3, ' ') : '   ')));

    // 如果為長龍情況
    if (isDragon) {
      // 長龍尾巴超出第一個畫面時
      if (xOfDragonTail + 1 >= COLUMNS) {
        // 從長龍的尾巴開始截取顯示長度
        return array.map((row) => row.slice(xOfDragonTail + 2 - COLUMNS, xOfDragonTail + 2));
      }
      // 否則就只顯示第一個畫面
      return array;

      // 不為長龍的情況
    }
    // 第一列長度超出第一個畫面時
    if (arrayOfRoad[0].length >= COLUMNS) {
      // 從第一列的尾端往前截取顯示長度
      return array.map((row) => row.slice(arrayOfRoad[0].length + 1 - COLUMNS, arrayOfRoad[0].length + 1));
    }
    // 否則就只顯示第一個畫面
    return array;
  };

  const winFlagArray: string[] = gameResultSets
    .reverse()
    .filter((gameResult) => !!gameResult.WinFlag) // 事後改牌等情況有可能會造成該局 WinFlag 被清空，此時應先過濾
    .map((gameResult) => (gameResult.WinFlag ? JSON.parse(gameResult.WinFlag).winner : 0));

  winFlagArray.forEach((winFlag, index, array) => {
    /** 如果為第一個劃記 */
    const isFirst = index === 0;

    /** 前一局的結果 */
    const prevWinFlag = array[index - 1];

    /** 當此局的贏家與上一局的贏家相同時 */
    const isWinningStreak = index > 0 && arrayOfRoad[y][x]?.includes(winFlag); // 連勝的狀況

    /** 是否會在 y 軸上與自己相連 */
    const willConnectToSelf = y < ROWS - 2 && arrayOfRoad[y + 2][x]?.includes(winFlag);

    /** 底下是否有空間（撇除碰撞底部或與其它長龍相撞的情況） */
    const isBottomArrangeable = y < ROWS - 1 && !arrayOfRoad[y + 1][x] && !willConnectToSelf;

    if (isFirst) {
      switch (winFlag) {
        case WinFlag.TIE:
          arrayOfRoad[y][x] = WinFlag.TIE;
          return;
        case Banker:
        case Player:
          arrayOfRoad[y][x] = winFlag;
          return;
        default:
          return;
      }
    }

    const timesOfTie = arrayOfRoad[y][x].replaceAll(new RegExp(`${Banker}|${Player}`, 'g'), '').length;

    switch (winFlag) {
      case WinFlag.TIE:
        // 如果上一局是莊贏或閒贏則直接紀錄和局
        if (prevWinFlag !== WinFlag.TIE) arrayOfRoad[y][x] += WinFlag.TIE;
        else if (timesOfTie < 2) {
          arrayOfRoad[y][x] += WinFlag.TIE;
        }
        break;
      case Banker:
      case Player:
        if (isWinningStreak) {
          if (isBottomArrangeable && !isDragon) {
            isDragon = false;
            y += 1; // 繼續往下排列
          } else {
            isDragon = true;
            x += 1; // 以長龍的方式往右繼續排列
            if (y === 0) xOfDragonHead = x;
            xOfDragonTail = x;
          }
        } else {
          // else (當此局的贏家與上一局的贏家不同時)
          isDragon = false;
          x = xOfDragonHead + 1; // 另起新行
          y = 0; // 並從最上面的列開始排
          xOfDragonHead = x;
          xOfDragonTail = x;
        }
        arrayOfRoad[y][x] = winFlag;
        break;
      default:
        break;
    }
  });

  const result = extendArrayOfRoad();
  return result;
};

/** 將陣列路圖轉換為圖片 */
const renderArrayOfRoad = (
  arrayOfRoad: Array<string[]>,
  { Banker, Player }: WinFlagMapping,
  canvasSettings: RoadmapSettings
) => {
  const { columns: COLUMNS, rows: ROWS, canvasWidth, canvasHeight, lineWidth, lineColor, cellPadding } = canvasSettings;

  if (!ctx) return '';

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  ctx.strokeStyle = lineColor;
  ctx.lineWidth = lineWidth;

  const cellWidth = canvasWidth / COLUMNS;
  const cellHeight = canvasHeight / ROWS;

  // 渲染邊框與格線;
  const renderGrid = () => {
    ctx.strokeRect(0, 0, canvasWidth, canvasHeight);
    ctx.stroke();
    for (let x = 0; x < COLUMNS; x += 1) {
      const fromX = x * cellWidth;
      ctx.moveTo(fromX, 0);
      ctx.lineTo(fromX, canvasHeight);
      for (let y = 0; y < ROWS; y += 1) {
        const fromY = y * cellHeight;
        ctx.moveTo(0, fromY);
        ctx.lineTo(canvasWidth, fromY);
      }
    }
    ctx.stroke();
  };

  // 渲染路子
  const draw = (image: HTMLImageElement, x: number, y: number, w = cellWidth, h = cellHeight) => {
    return ctx?.drawImage(
      image,
      x * cellWidth + cellPadding,
      y * cellHeight + cellPadding,
      w - cellPadding * 2,
      h - cellPadding * 2
    );
  };

  arrayOfRoad.forEach((row, y) => {
    const excludeWinnerString = (cellString: string) => {
      const r = new RegExp(`${Banker}|${Player}`);
      return cellString.replace(r, '');
    };

    row.forEach((cell, x) => {
      if (cell.includes(Banker)) draw(BANKER_WIN, x, y);
      if (cell.includes(Player)) draw(PLAYER_WIN, x, y);
      if (excludeWinnerString(cell).includes('T')) draw(TIE, x, y);
      if (excludeWinnerString(cell).includes('TT')) draw(TIES, x, y);
    });
  });

  renderGrid();

  return canvas.toDataURL();
};

/**
 * 目前路圖預設為 14 行 5 列
 *
 * 根據此規則，在傳入 canvasSettings 的 canvasWidth, canvasHeight 屬性時請自行計算所需大小
 *
 * 如希望路圖每個方格為正方形 30px，則傳入
 *
 * 寬 14 * 30 = 420
 *
 * 高  5 * 30 = 150
 *
 * **另外為避免圖片拉伸所造成的模糊，寬高請大於容器本身的設定**
 *
 * @param gameResultSets 遊戲結果陣列
 * @param canvasSettings 畫布設定
 * @returns Base64 Image
 */

export default function renderRoadmap(
  gameResultSets: Readonly<GameResultSet[]>,
  winFlagMapping: WinFlagMapping,
  roadmapSettings: Partial<RoadmapSettings> = {}
) {
  const combinedRoadmapSettings = {
    ...{
      /** 欄數 */
      columns: 14,

      /** 列數 */
      rows: 5,

      /** 畫布寬度 */
      canvasWidth: 420,

      /** 畫布高度 */
      canvasHeight: 150,

      /** 線條寬度 */
      lineWidth: 1,

      /** 線條顏色 */
      lineColor: '#9CA3AF',

      /** 每一格中元素與邊框的距離 */
      cellPadding: 2.5
    },
    ...roadmapSettings
  };

  /*
   * 由於內部的 reverse 會變更外部傳進來的 GameResult，故在此先深拷貝一份以供內部變更
   * 但在拷貝後的物件依然會被 readonly 限制無法使用 reverse，故在此處使用 as 強制轉型
   */
  const clonedGameResutSets = cloneDeep(gameResultSets) as GameResultSet[];

  const arrayOfRoad = createArrayOfRoad(clonedGameResutSets, winFlagMapping, combinedRoadmapSettings);
  const roadMap = renderArrayOfRoad(arrayOfRoad, winFlagMapping, combinedRoadmapSettings);
  return roadMap;
}
