<template>
  <div
    class="h-full flex flex-col items-center justify-center py-8"
    style="background: linear-gradient(195.03deg, #6366f1 -37.63%, #1f2937 76.08%)"
    v-loading="loading"
  >
    <div
      class="text-4xl font-normal mb-8 bg-gradient-to-b from-indigo-400 to-sky-200 bg-clip-text"
      style="-webkit-text-fill-color: transparent"
    >
      App Download
    </div>

    <div class="px-4 py-6 shadow rounded-lg bg-white w-[500px]">
      <form>
        <div class="grid gap-5 divide-y divide-gray-200">
          <div class="flex gap-4 pt-5 text-xl font-bold">
            <div class="flex items-center justify-end text-gray-700 w-[120px]">{{ t('Dealer.DownloadVersion') }}</div>
            <div class="flex flex-1 justify-center">
              <label v-for="option in versionOption" :key="option" class="flex items-center mr-3 cursor-pointer">
                <input
                  v-model="version"
                  :value="option"
                  :id="option"
                  type="radio"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <div :for="option" class="ml-2 block font-medium text-gray-700">
                  {{ option }}
                </div>
              </label>
            </div>
          </div>
          <div class="flex gap-4 pt-5 text-xl font-bold">
            <div class="flex items-center justify-end text-gray-700 w-[120px]">{{ t('Dealer.FileName') }}</div>
            <div class="text-gray-700 text-lg text-center flex-1">{{ t(targetAppName) }}</div>
          </div>

          <div class="pt-5 flex justify-center">
            <a :href="downloadLink" :class="{ 'pointer-events-none': !isAllowDownload }">
              <SBtn type="button" :class="[isAllowDownload ? 'bg-indigo-500' : 'bg-gray-300']" size="xl">
                {{ t('Common.Download') }}
              </SBtn>
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { onMounted, ref, watch, computed } from 'vue';
import axios from 'axios';

/* 內部方法 */
import useI18n from '@sms/common/composables/useI18n';

/* 內部組件 */
import SBtn from '@sms/components/SBtn/SBtn.vue';

/* 型別 */
type VersionOption = 'alpha' | 'beta' | 'latest';

/* 系統元件 */
const { fetchTranslation, t } = useI18n();
const loading = ref(false);

const versionOption = ['alpha', 'beta', 'latest'];

const version = ref<VersionOption>('alpha');

const targetAppName = ref('');

const downloadLink = computed(() => `${import.meta.env.VITE_MINIO_HOST}shell-dealer/${targetAppName.value}`);

const isAllowDownload = ref(false);

/** 注入語系、開發用預設資料 */
const initData = async () => {
  try {
    loading.value = true;

    await fetchTranslation();

    loading.value = false;
  } catch (error) {
    loading.value = true;
  }
};

watch(
  version,
  async () => {
    try {
      targetAppName.value = '';
      const { data } = await axios.get<string>(`${import.meta.env.VITE_MINIO_HOST}shell-dealer/${version.value}.yml`);

      targetAppName.value = data.match(/url: (.*)/)?.[1] ?? '';
      isAllowDownload.value = true;
    } catch (error) {
      targetAppName.value = 'Dealer.ConnectError';
      isAllowDownload.value = false;
    }
  },
  { immediate: true }
);

onMounted(async () => {
  initData();
});
</script>
