/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type MemberSet from '@sms/common/models/MemberSet';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';

export default {
  get(Id: string): AxiosPromise<ResponseBaseModel<MemberSet>> {
    return api.get(`/Member/${Id}`);
  }
};
