/* 外部方法 */
import axios from 'axios';
import axiosRetry from 'axios-retry';

/* 內部方法 */
import useRootStore from '@/store/modules/root';

/* 型別 */
import type { AxiosInstance, AxiosPromise } from 'axios';
import type StreamStateLogSet from '@sms/common/models/StreamStateLogSet';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';

// 這邊的回報不觸發 CustomError

const api: AxiosInstance = axios.create({
  baseURL: '/api'
});

api.interceptors.request.use((config) => {
  const rootStore = useRootStore();

  config.headers.set('Authorization', `Bearer ${rootStore.token}`);
  if (typeof config.data === 'string') {
    config.data = JSON.parse(config.data);
  }
  config.data.SubmitDatetime = new Date();

  return config;
});

axiosRetry(api, {
  retries: Infinity,
  retryDelay: axiosRetry.exponentialDelay
});

export default {
  post(state: StreamStateLogSet): AxiosPromise<ResponseBaseModel<StreamStateLogSet>> {
    return api.post('/StreamStateLog', state);
  }
};
