/* 外部方法 */
import { ref } from 'vue';
import { defineStore } from 'pinia';
import Peer from 'simple-peer';

/* 型別 */
import type PeerHostResponse from '@sms/common/interfaces/PeerHostResponse';
import type PeerClientRequest from '@sms/common/interfaces/PeerClientRequest';
import type { PeerWokerProcess, PeerMainProcess } from '@sms/common/interfaces/WorkerFileSplit';
import type { FilePackage } from '@sms/common/interfaces/FileSystem';

export default defineStore('peer', () => {
  /** 連線物件 */
  let peer: Peer.Instance | null;

  /** 判斷是否連線中 */
  const isConnecting = ref(false);

  const isListening = ref(false);

  const queueLength = ref(0);

  /** 連線設定 */
  const peerConfig: Peer.Options = {
    initiator: false,
    trickle: false,
    config: {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302'
        },
        {
          urls: 'stun:stun1.l.google.com:19302'
        },
        {
          urls: 'stun:stun2.l.google.com:19302'
        },
        {
          urls: 'stun:stun3.l.google.com:19302'
        },
        {
          urls: 'stun:stun4.l.google.com:19302'
        },
        {
          urls: import.meta.env.VITE_PEER_HOST,
          username: 'admin',
          credential: 'admin'
        }
      ]
    }
  };

  /** 子線程用於切割檔案 */
  let worker: Worker | undefined;

  /* 連線相關 */
  /** 建立連線 */
  const peerConnect = (signal: string) => {
    if (!peer) {
      peer = new Peer(peerConfig);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      peerHandlerSetting();
    }
    peer?.signal(signal);
  };

  /** 發送訊息 */
  const peerSend = (res: PeerHostResponse) => {
    peer?.send(JSON.stringify(res));
  };

  /** 關閉連線 */
  const peerDisconnect = () => {
    peer?.destroy();
  };

  const initWorker = () => {
    queueLength.value = 0;

    worker = new Worker(new URL('../../utilities/workerFileSplit.ts', import.meta.url), {
      type: 'module'
    });

    worker.addEventListener('message', ({ data }: MessageEvent<PeerWokerProcess>) => {
      const { type } = data;

      // 這裡其實可以不要用 switch case，但是為了方便日後擴充，所以還是用 switch case
      switch (type) {
        case 'StartTransfer':
        case 'TransferChunk':
        case 'FinishTransfer':
          peerSend(data);
          return;
        case 'updateQueueLength':
          queueLength.value = data.queueLength;
          return;
        default:
          return;
      }
    });

    worker.addEventListener('error', (e) => {
      console.error(e);
    });
  };

  const workerPostMessage = (message: PeerMainProcess) => {
    worker?.postMessage(message);
  };

  /* 傳輸檔案相關 */
  /** 開始傳輸檔案 */
  const startTransfer = (FilePackage: FilePackage) => {
    // 建立子線程
    if (!worker) initWorker();

    // 發送檔案資訊進子線程
    workerPostMessage({ type: 'StartTransfer', ...FilePackage });
  };

  /** 檔案接收事件 */
  const peerDataHandler = async (clientRequest: PeerClientRequest) => {
    switch (clientRequest.type) {
      case 'GetRootDirectory':
        if (!window.getRootDirectory) return;

        peerSend({
          type: 'FolderList',
          data: await window.getRootDirectory()
        });
        return;
      case 'GetDirectory':
        if (!window.getDirectory) return;

        peerSend({
          type: 'FolderList',
          data: await window.getDirectory(clientRequest.path, clientRequest?.page, clientRequest?.pageSize)
        });
        return;
      case 'GetFileBuffer':
        if (!window.getFileBuffer || !window.ipcRendererService) return;

        window.ipcRendererService.once('file-transfer', (_event, data) => {
          startTransfer(data);
        });

        window.getFileBuffer(clientRequest.path);
        return;
      case 'StartTransferAck':
      case 'TransferChunkAck':
        worker?.postMessage(clientRequest);
        return;
      case 'LastChunkAck':
        workerPostMessage({ type: 'FinishTransferAck' });
        return;
      case 'StartListening':
        isListening.value = true;
        return;
      case 'StopListening':
        isListening.value = false;
        worker?.terminate();
        worker = undefined;
        initWorker();
        return;
      default:
        return;
    }
  };

  let invokeSignal: (signal: string) => void;

  const setInvokeFunction = (invoke: (signal: string) => void) => {
    invokeSignal = invoke;
  };

  /** 連線的事件設定 */
  function peerHandlerSetting() {
    if (!peer) return;

    peer.on('signal', (data) => {
      if (invokeSignal) invokeSignal(JSON.stringify(data));
    });

    peer.on('connect', () => {
      isConnecting.value = true;

      if (!worker) initWorker();
    });

    peer.on('data', async (data) => {
      const clientRequest: PeerClientRequest = JSON.parse(data);
      peerDataHandler(clientRequest);
    });

    peer.on('error', (err) => {
      console.warn('error', err);
    });

    peer.on('close', () => {
      isConnecting.value = false;
      isListening.value = false;

      worker?.terminate();
      worker = undefined;

      peer = new Peer(peerConfig);

      peerHandlerSetting();
    });
  }

  return {
    isConnecting,
    isListening,
    queueLength,
    setInvokeFunction,
    peerConnect,
    peerDisconnect,
    startTransfer
  };
});
