/* 外部方法 */
import { defineStore } from 'pinia';
import { sortBy } from 'lodash-es';

/* 內部方法 */
import { nestFlatten } from '@sms/common/utils/helper';
import useI18n from '@sms/common/composables/useI18n';

/* API */
import memberService from '@/api/ajax/memberService';

/* 型別 */
import type DeskSet from '@sms/common/models/DeskSet';
import type LocationSet from '@sms/common/models/LocationSet';
import type MemberSet from '@sms/common/models/MemberSet';

export default defineStore('root', {
  state: () => ({
    desks: [] as DeskSet[],
    locations: [] as LocationSet[],

    deskId: '',
    pbId: '',
    deskLocationList: [] as LocationSet[],
    token: '',
    currentPbId: '',
    currentDealerInfo: null as MemberSet | null
  }),

  getters: {
    availableDesks(state) {
      return sortBy(state.desks, ['Code', 'Seq']);
    },

    currentDesk(state) {
      return state.desks.find((desk) => desk.Id === state.deskId);
    },

    locationList(state) {
      return sortBy(nestFlatten(state.locations), ['Code']);
    },

    dealerReady(state) {
      return !!state.currentDealerInfo?.Id;
    },

    flattenDeskLocationList(state) {
      return nestFlatten(state.deskLocationList);
    }
  },

  actions: {
    setPBID(payload: string) {
      this.pbId = payload;
    },

    setDeskLocationList(payload: LocationSet[]) {
      this.deskLocationList = payload;
    },

    setCurrentPBID(payload: string) {
      this.currentPbId = payload;
    },

    setToken(payload: string) {
      this.token = payload;
    },

    setCurrentDealerInfo(dealerInfo: MemberSet | null) {
      this.currentDealerInfo = dealerInfo;
    },

    /** 更新荷官資訊，另外會用於判斷目前語系 */
    async updateDealerInfo(dealerId: string) {
      try {
        const { setLocale } = useI18n();
        const res = await memberService.get(dealerId);
        const { Data } = res.data;
        this.setCurrentDealerInfo(Data);
        if (Data.LangTypeCode) setLocale(Data.LangTypeCode);
      } catch (error) {
        this.setCurrentDealerInfo(null);
        throw error;
      }
    }
  }
});
