<template>
  <div
    class="h-full flex flex-col items-center justify-center py-8"
    style="background: linear-gradient(195.03deg, #6366f1 -37.63%, #1f2937 76.08%)"
  >
    <div
      class="text-4xl font-normal mb-8 bg-gradient-to-b from-indigo-400 to-sky-200 bg-clip-text"
      style="-webkit-text-fill-color: transparent"
    >
      {{ t('Dealer.Login_Interface') }}
    </div>

    <div class="max-w-xl w-full px-16 py-12 rounded-2xl bg-white mb-[75px]">
      <form @submit.prevent="submit">
        <div>
          <div
            :class="['flex bg-[#EDEEEF] rounded-[5px] border', formErrors.pbId ? 'border-red-500' : 'border-[#e2e2e2]']"
          >
            <div class="w-[54px] flex items-center justify-center">
              <SIcon :icon="mdiAccount" class="w-6 h-6 text-zinc-500" />
            </div>

            <div class="w-px bg-[#e2e2e2] my-[10px]"></div>

            <div class="flex-1 rounded-r-md">
              <input
                id="pbId"
                v-model="form.pbId"
                type="text"
                :class="[
                  'w-full rounded-r-[5px] text-sm h-12 bg-[#EDEEEF] px-3 py-2 border-none outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-2',
                  formErrors.pbId ? 'text-red-500' : 'text-gray-600'
                ]"
                :placeholder="t('Dealer.Login_PBId')"
                :disabled="!(isLocal || isQA) || loading.visible.value"
                @blur="validate"
                @touchcancel="validate"
                @touchend="validate"
              />
            </div>
          </div>
        </div>

        <SBtn type="submit" class="w-full !h-[50px] bg-indigo-500 mt-5">
          {{ t('Common.Login') }}
        </SBtn>

        <div v-if="formErrors.pbId" class="leading-4 text-sm text-red-500 text-center mt-6">
          <span>{{ formErrors.pbId }}</span>
        </div>
      </form>
    </div>

    <AppSection />
  </div>

  <div class="absolute top-0 right-0 p-6 flex">
    <button
      type="button"
      class="relative inline-flex items-center px-4 py-2 disabled:opacity-40 rounded-md border border-gray-300 bg-red-400 text-sm font-medium text-white hover:bg-red-500 hover:text-red-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
      @click="requestHidDevice"
    >
      {{ t('Dealer.Login_BindScanner', { count: hidScanners.length }) }}
    </button>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { onBeforeUnmount, reactive, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ValidationError } from 'yup';
import { storeToRefs } from 'pinia';

/* 內部方法 */
import store from '@/store';
import { yupObject, string } from '@sms/common/plugins/yup';
import useI18n from '@sms/common/composables/useI18n';
import useLoading from '@sms/common/composables/useLoading';
import useScannerParser from '@sms/common/composables/useScannerParser';
import { isLocal, isQA } from '@sms/common/utils/helper';

/* 外部組件 */
import { mdiAccount } from '@mdi/js';

/* 內部組件 */
import SIcon from '@sms/components/SIcon/SIcon.vue';
import SBtn from '@sms/components/SBtn/SBtn.vue';
import AppSection from '@/components/AppSection.vue';

/* API */
import authService from '@/api/ajax/authService';

/** 型別 */
import CustomError from '@sms/common/models/CustomError';

/* 系統元件 */
const router = useRouter();
const { t } = useI18n();
const loading = useLoading();

/* Stores */
const rootStore = store.useRootStore();
const modalStore = store.useModalStore();
const streamStore = store.useStreamStore();
const socketStore = store.useSocketStore();

const { isSocketConnected, socket } = storeToRefs(socketStore);

/* 表單相關 */
const form = reactive({
  pbId: ''
});

const formErrors = reactive({
  pbId: ''
});

const schema = computed(() =>
  yupObject({
    pbId: string().uuid(t('Error.Input_Valid_UUID')).required(t('Error.Input_Valid_Required'))
  })
);

const validate = () => {
  try {
    // 重置錯誤訊息
    formErrors.pbId = '';

    schema.value.validateSync({ pbId: form.pbId }, { abortEarly: false });

    return true;
  } catch (error) {
    // 顯示 yup 驗證訊息 (多驗證規則下也只顯示一個)
    if (error instanceof ValidationError) {
      const keys = Object.keys(formErrors);

      error.inner.forEach((x) => {
        if (x.path && keys.includes(x.path)) {
          const key = x.path as keyof typeof formErrors;
          formErrors[key] = x.message;
        }
      });
    }

    return false;
  }
};

/** PB 登入 */
const submit = async () => {
  try {
    const isValid = validate();
    if (!isValid) return;

    loading.show();

    const pbInfoResp = await authService.pbInfo(form.pbId);

    rootStore.setCurrentPBID(form.pbId);
    rootStore.setDeskLocationList(pbInfoResp.data.Data.DeskLocationList);

    router.replace({ name: 'DeskLogin' });
  } catch (error) {
    if (error instanceof CustomError) {
      formErrors.pbId = error.message;
    }
  } finally {
    loading.hide();
  }
};

/* 掃描相關 */
const { hidScanners, requestHidDevice, scannerEvent } = useScannerParser();

const scanHandler = (str: string) => {
  form.pbId = str;
  submit();
};

scannerEvent.on('MEMBER', scanHandler);
onBeforeUnmount(() => scannerEvent.off('MEMBER', scanHandler));

/** 重置 Store */
const resetStore = () => {
  rootStore.$reset();
  modalStore.clear();
  streamStore.clear();
};

const initData = () => {
  resetStore();

  if (isSocketConnected.value) socket.value.stop();

  if (import.meta.env.DEV) {
    form.pbId = '33c995fc-fdd7-4bce-941c-a805414a8cba';
  }
};

onMounted(() => {
  // 當使用者被導回登入頁時(例如同帳號在另一裝置登入)清除未關閉的提示燈箱
  modalStore.clear();
  initData();
});
</script>

<style scoped>
.progress {
  border-color: rgba(255, 255, 255, 0.25);
}
.progress__indeterminate {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  background: linear-gradient(269.67deg, #fff7ed -49.96%, #fed7aa 5.65%, #fb923c 100.61%);
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.2);
  height: inherit;
  animation-play-state: running;
  animation-duration: 2.2s;
  animation-iteration-count: infinite;
  border-radius: 10px;
}
</style>
