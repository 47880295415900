/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type LocationSet from '@sms/common/models/LocationSet';

interface PbInfoResponse {
  DeskLocationList: LocationSet[];
  ShuffleDeskLocationList: LocationSet[];
  ShuffleRoomLocationList: LocationSet[];
}

interface LoginResponse {
  token: string;
}

export default {
  check(): AxiosPromise<ResponseBaseModel<boolean>> {
    return api.get('/Auth/Check');
  },

  pbInfo(PbId: string): AxiosPromise<ResponseBaseModel<PbInfoResponse>> {
    return api.post('/Auth/PbInfo', { PbId });
  },

  login(PbId: string, DeskLocationId: string): AxiosPromise<ResponseBaseModel<LoginResponse>> {
    return api.post('/Auth', { PbId, DeskLocationId });
  }
};
