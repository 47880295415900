/* 外部方法 */
import {
  string,
  array,
  number,
  boolean,
  date,
  object as yupObject,
  addMethod,
  StringSchema,
  bool,
  NumberSchema
} from 'yup';
import stringWidth from 'string-width';

/* 內部方法 */
import useI18n from '../composables/useI18n';
import { isNull, isUndefined } from 'lodash-es';

const { t } = useI18n();

declare module 'yup' {
  interface StringSchema {
    numbericOrLetterOrChinese(message?: string): StringSchema;
    numbericOrLetter(message?: string): StringSchema;
    excludeSpecialChar(message?: string): StringSchema;
    excludeSymbolNum(message?: string): StringSchema;
    excludeSymbol(message?: string): StringSchema;
    password(message?: string): StringSchema;
    minWidth(minLimit: number, message?: string): StringSchema;
    maxWidth(maxLimit: number, message?: string): StringSchema;
    width(limit: number): StringSchema;
    phoneNumber(): StringSchema;
    bwh(): StringSchema;
  }

  interface NumberSchema {
    decimal(limit: number): NumberSchema;
  }
}

/**
 * 規則擴充
 */

// 僅限英文、數字、中文
function numbericOrLetterOrChinese(this: StringSchema, message = t('Error.NumbericOrLetterOrChinese')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/^[A-Za-z0-9\u4E00-\u9FA5\uF900-\uFA2D]+$/gu, options);
}

// 僅限英文、數字
function numbericOrLetter(this: StringSchema, message = t('Error.NumbericOrLetter')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/^[A-Za-z0-9]+$/gu, options);
}

// 驗證是否包含特殊字符
function excludeSpecialChar(this: StringSchema, message = t('Error.ExcludeSpecialChar')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/[^@#$%^&*!+=|\\<>{}[\]\\?]+$/gu, options);
}

// 排除特殊符號、數字
function excludeSymbolNum(this: StringSchema, message = t('Error.DoNotIncludeSpecialSymbolsAndNumber')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/(^[\p{Alphabetic}\p{Mark}\p{Connector_Punctuation}\p{Join_Control}]+$)/gu, options);
}

// 排除 ._ 以外的特殊符號
function excludeSymbol(this: StringSchema, message = t('Error.DoNotIncludeSpecialSymbols')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(
    /(^[\p{Alphabetic}\p{Mark}\p{Decimal_Number}\p{Connector_Punctuation}\p{Join_Control}._]+$)/gu,
    options
  );
}

// 僅限數字 以及 + -
function phoneNumber(this: StringSchema, message = t('Error.PhoneNumber')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/(^[\p{Decimal_Number}+-]+$)/gu, options);
}

// 僅限英文數字 以及 -
function bwh(this: StringSchema, message = t('Error.Bwh')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/(^[\p{Decimal_Number}A-Za-z-]+$)/gu, options);
}

// 密碼限制，參照後端的驗證的Regex SpecialPassword，後台、PB共用此regex
function password(this: StringSchema, message = t('Common.Password_Rule')) {
  const options = { message, excludeEmptyString: true };
  return this.matches(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[_\W]).{12,24})$/gm, options);
}

// 根據文字寬度限制最小字元數;
function minWidth(this: StringSchema, minLimit: number, message?: string) {
  return this.test(
    'MinWidth',
    message ? message : t('Error.MinLength', { number: minLimit }),
    (value: string | undefined) => (value ? stringWidth(value) >= minLimit : true)
  );
}

// 根據文字寬度限制最大字元數;
function maxWidth(this: StringSchema, maxLimit: number, message?: string) {
  return this.test(
    'MaxWidth',
    message ? message : t('Error.MaxLength', { number: maxLimit }),
    (value: string | undefined) => (value ? stringWidth(value) <= maxLimit : true)
  );
}

// 根據文字寬度限制字元數
function width(this: StringSchema, limit: number) {
  return this.test('Width', t('Error.LengthRequired', { number: limit }), (value: string | undefined) =>
    value ? stringWidth(value) === limit : true
  );
}

// 驗證小數點
function decimal(this: NumberSchema, limit: number) {
  const message = limit === 0 ? t('Error.MustBeInteger') : t('Error.DecimalError', { number: limit });

  return this.test('Decimal', message, (value: number | undefined) => {
    if (isUndefined(value) || isNull(value)) return true;
    if (limit === 0) return /^-?\d+$/.test(value.toString());

    const regex = new RegExp(`^-?\\d+(\\.\\d{1,${limit}})?$`);
    return regex.test(value.toString());
  });
}

addMethod(string, 'numbericOrLetterOrChinese', numbericOrLetterOrChinese);
addMethod(string, 'numbericOrLetter', numbericOrLetter);
addMethod(string, 'excludeSpecialChar', excludeSpecialChar);
addMethod(string, 'excludeSymbolNum', excludeSymbolNum);
addMethod(string, 'excludeSymbol', excludeSymbol);
addMethod(string, 'password', password);
addMethod(string, 'minWidth', minWidth);
addMethod(string, 'maxWidth', maxWidth);
addMethod(string, 'width', width);
addMethod(string, 'phoneNumber', phoneNumber);
addMethod(string, 'bwh', bwh);
addMethod(number, 'decimal', decimal);

export { string, array, number, boolean, date, yupObject, bool };
