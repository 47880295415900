export const logicWidth = 1920;
export const logicHeight = 1080;
export const aspectRatio = '16:9';

/**
 * 註冊全域快捷鍵
 * @param combinedKeys 組合鍵，目前只支援 event.code
 * @param callback 任意鍵盤事件觸發時的函式
 * @returns 取消註冊用的函式
 */
export const hotkeyRegister = (combinedKeys: string[], callback: () => void) => {
  const currentKeys: string[] = [];
  const timers: Record<string, number> = {};

  const onKeyDown = (event: KeyboardEvent) => {
    if (!combinedKeys.includes(event.code)) return;
    if (currentKeys.includes(event.code)) return;
    const { code } = event;

    currentKeys.push(code);

    // 500 毫秒後清除按鍵
    if (timers[code]) window.clearTimeout(timers[code]);
    timers[code] = window.setTimeout(() => currentKeys.splice(currentKeys.indexOf(code), 1), 500);

    if (currentKeys.length !== combinedKeys.length) return;
    if (!currentKeys.every((key) => combinedKeys.includes(key))) return;

    callback();
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (!combinedKeys.includes(event.code)) return;
    currentKeys.splice(currentKeys.indexOf(event.code), 1);
  };

  window.addEventListener('keydown', onKeyDown);
  window.addEventListener('keyup', onKeyUp);

  return () => {
    window.removeEventListener('keydown', onKeyDown);
    window.removeEventListener('keyup', onKeyUp);
  };
};
