export default class BlankResult {
  R01: string | null = null;

  R02: string | null = null;

  R03: string | null = null;

  R04: string | null = null;

  R05: string | null = null;

  R06: string | null = null;

  R07: string | null = null;

  R08: string | null = null;

  R09: string | null = null;

  R10: string | null = null;

  R11: string | null = null;

  R12: string | null = null;

  R13: string | null = null;

  R14: string | null = null;

  R15: string | null = null;

  R16: string | null = null;

  R17: string | null = null;

  R18: string | null = null;

  R19: string | null = null;

  R20: string | null = null;
}
