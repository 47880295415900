/* 外部方法 */
import { onMounted, onBeforeUnmount, ref } from 'vue';

/* 型別 */
import type { IpcRendererEvent } from 'electron';
import type AppState from '../interfaces/AppState';
import type ProgressInfo from '../interfaces/ProgressInfo';

export default function useElectronUpdate() {
  const appState = ref<AppState>({
    update: {
      checking: false,
      updateAvailable: false,
      downloaded: false
    }
  });

  const isDownloading = ref(false);

  const updateProgress = ref('0');

  const isDownloadError = ref(false);

  const appUpdate = () => window?.updateAndRestart && window.updateAndRestart();

  const handleuUpdateAvailable = async () => {
    isDownloading.value = false;
    isDownloadError.value = false;

    if (window.getState) appState.value = await window.getState();
  };

  const handleDownloadProgress = (event: IpcRendererEvent, args: ProgressInfo) => {
    isDownloading.value = true;
    updateProgress.value = (100 - args.percent).toFixed(2);
  };

  const handleDownloadError = (event: IpcRendererEvent, args: string) => {
    if (args !== 'error') return;
    isDownloading.value = false;
    isDownloadError.value = true;
  };

  onMounted(async () => {
    if (window.getState) appState.value = await window.getState();

    if (!window.ipcRendererService) return console.log('ipcRendererService is undefined');

    window.ipcRendererService.on('update-available', handleuUpdateAvailable);
    window.ipcRendererService.on('download-error', handleDownloadError);
    window.ipcRendererService.on('download-progress', handleDownloadProgress);
  });

  onBeforeUnmount(() => {
    if (!window.ipcRendererService) return;
    window.ipcRendererService.off('update-available', handleuUpdateAvailable);
    window.ipcRendererService.off('download-error', handleDownloadError);
    window.ipcRendererService.off('download-progress', handleDownloadProgress);
  });

  return {
    appState,
    isDownloading,
    updateProgress,
    isDownloadError,
    appUpdate
  };
}
