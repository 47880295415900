/* 外部方法 */
import { readonly } from 'vue';
import { defineStore } from 'pinia';

/* 型別 */
import DeskSet from '@sms/common/models/DeskSet';
import type AuxiliaryLineSet from '@sms/common/models/AuxiliaryLineSet';

export default defineStore('desk', {
  /* 不 要 自 行 變 更 此 處 的 DeskData */
  state: () => ({
    deskData: new DeskSet(), // 不要動！！！
    isEmergencyLayoutReady: false,
    showEmergencyLayoutTimer: undefined as number | undefined
  }),

  getters: {
    readonlyDeskData: (state) => readonly(state.deskData)
  },

  actions: {
    setIsEmergencyLayoutReady(payload: boolean) {
      this.isEmergencyLayoutReady = payload;
    },
    setShowEmergencyTimer() {
      window.clearTimeout(this.showEmergencyLayoutTimer);

      this.showEmergencyLayoutTimer = window.setTimeout(() => {
        this.isEmergencyLayoutReady = false;
        this.showEmergencyLayoutTimer = undefined;
      }, 5 * 1000);
    },
    setDeskWithoutAuxiliary(serverDeskData: DeskSet) {
      // 避免伺服器意外地傳送空物件
      if (!serverDeskData) return;

      /** 確認是否為同一桌的資料 */
      const isSameDesk = this.deskData.Id === serverDeskData.Id;

      // 如果是同一桌的資料
      if (isSameDesk) {
        const isLatestData = +new Date(serverDeskData.UpdateDatetime) >= +new Date(this.deskData.UpdateDatetime);

        // 比對是否為最新資料
        if (!isLatestData) return;

        // 使用舊的標線資料
        const AuxiliaryLineSets = this.deskData.AuxiliaryLineSets;
        this.deskData = { ...serverDeskData, AuxiliaryLineSets };
      } else {
        this.deskData = serverDeskData;
      }
    },
    setAuxiliary(serverAuxiliaryLineData: AuxiliaryLineSet[]) {
      // 避免伺服器意外地傳送空物件
      if (!serverAuxiliaryLineData) return;

      /** 確認是否為同一桌的資料 */
      const isSameDesk = serverAuxiliaryLineData.every((auxiliaryLine) => auxiliaryLine.DeskId === this.deskData.Id);

      // 如果是同一桌的資料
      if (isSameDesk) {
        this.deskData.AuxiliaryLineSets = serverAuxiliaryLineData;
      }
    }
  }
});
