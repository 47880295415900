<template>
  <AspectContainer
    class="subpixel-antialiased bg-gray-800 text-white"
    :aspect-ratio="aspectRatio"
    :render-height="logicHeight"
    :render-width="logicWidth"
  >
    <LoadingOverlay
      v-model:show="systemLoading.show"
      v-model:reload="systemLoading.reload"
      :api-callback="fetchSystemAPI"
      site="dealer"
    />

    <SystemLayout>
      <router-view />
      <template #status-bar>
        <StatusBar />
      </template>
    </SystemLayout>

    <SModal
      v-for="(modal, index) in modals"
      :key="index"
      :closable="false"
      :model-value="true"
      :options="modal"
      :content-class="modal.contentClass"
      @on-cancel="modal.onCancel && modal.onCancel()"
      @on-confirm="modal.onConfirm && modal.onConfirm()"
      teleport-disabled
    />

    <SMessage />

    <SLoading
      v-model="loading.visible.value"
      :loading-text="loading.text.value"
      :loading-color="loading.color.value"
      teleport-disabled
    />
  </AspectContainer>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { reactive, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

/* 內部方法 */
import store from '@/store';
import { aspectRatio, logicHeight, logicWidth, hotkeyRegister } from '@/utilities/helper';
import { pokerLoader } from '@sms/common/utils/poker';
import { roadmapLoader } from '@sms/common/utils/RoadHelper';
import { initAxiosCustomErrorInterceptor } from '@/api/ajax/settings';
import useLoading from '@sms/common/composables/useLoading';
import useModal from '@/composables/useModal';
import useFontFamily from '@sms/common/composables/useFontFamily';
import useScannerParser from '@sms/common/composables/useScannerParser';
import useI18n from '@sms/common/composables/useI18n';
import useDetectAbnormalKeypress from '@sms/common/composables/useAbnormalKeypress';

/* 內部組件 */
import SMessage from '@sms/components/SMessage/SMessage.vue';
import SModal from '@sms/components/SModal/SModal.vue';
import SLoading from '@sms/components/SLoading/SLoading.vue';
import StatusBar from './components/StatusBar.vue';
import SystemLayout from '@/layouts/SystemLayout.vue';
import AspectContainer from '@sms/common/components/AspectContainer/AspectContainer.vue';
import LoadingOverlay from '@sms/common/components/LoadingOverlay/LoadingOverlay.vue';

/* API */
import flagMapService from '@/api/ajax/flagMapService';

/* 系統元件 */
const router = useRouter();
const { fetchTranslation } = useI18n();

/* Stores */
const flagMapStore = store.useFlagMapStore();
const socketStore = store.useSocketStore();
const modalStore = store.useModalStore();

/* Socket 相關 */
const { startUpdateSocketState, stopUpdateSocketState } = socketStore;
const { isSocketConnected } = storeToRefs(socketStore);

/* 全域 Modal 控制 */
const { modals } = storeToRefs(modalStore);

/* 全域 Loading 控制 */
const loading = useLoading();

/* 字型控制 */
useFontFamily();

/* 啟用鍵盤 scanner 模式 */
const { enableKeyboardMode } = useScannerParser();
enableKeyboardMode();

/* 初始化 Ajax 請求的錯誤處理方式 */
initAxiosCustomErrorInterceptor(router, useModal);

/* 註冊 Numpad 的除號、乘號一起按切換全螢幕 */
hotkeyRegister(['NumpadDivide', 'NumpadMultiply'], () => window.toggleFullScreen?.());

/* 鍵盤異常、故障監測 */
const { modalOk } = useModal();
useDetectAbnormalKeypress(modalOk);

/**
 * 系統 Loading 相關
 * 進入系統前必須等待 `LoadingOverlay.vue` 將系統所需的 API 讀取完成
 */
const systemLoading = reactive({
  show: false,
  reload: false
});

const fetchSystemAPI = async () => {
  await Promise.all([
    fetchTranslation(),
    flagMapStore.fetchFlagMapData(flagMapService.getAll),
    pokerLoader.load(),
    roadmapLoader.load()
  ]);
};

/* 更新 Socket 狀態並在斷線後導回登入頁 */
onMounted(() => {
  if (!isSocketConnected.value) router.replace({ name: 'Login' });

  window.hideLoadingView?.();
  if (localStorage.getItem('debug') === '*' || localStorage.getItem('debug') === 'main') {
    window.ipcRendererService?.on('main-log', (event, { message, level }) => {
      ((console[level] as any) || console.log)('[main]', message);
    });
  }
});

onMounted(startUpdateSocketState);
onBeforeUnmount(stopUpdateSocketState);
</script>

<style lang="scss">
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&family=Noto+Sans+TC:wght@300;400;500;700&family=Noto+Sans:wght@400;500;700&display=swap');

html,
body,
#app {
  height: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
</style>
