<template>
  <div class="absolute origin-top-left" :class="setting.rootClass" :style="setting.rootStyle">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, computed } from 'vue';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  renderHeight: { type: Number, default: 1080 },
  renderWidth: { type: Number, default: 1920 },
  aspectRatio: { type: String, default: '16:9' }
});

interface emitType {
  (e: 'change', data: number): void;
}
const emit = defineEmits<emitType>();

const { width, height } = useWindowSize();

const setting = computed(() => {
  const [aspectW, aspectH] = props.aspectRatio.split(':').map(Number);

  const shrinkWidth = (width.value / aspectW) * aspectH > height.value;

  const ratio = shrinkWidth ? height.value / props.renderHeight : width.value / props.renderWidth;

  const rootClass = shrinkWidth
    ? 'left-1/2 top-0 -translate-x-1/2 translate-y-0'
    : 'left-0 top-1/2 translate-x-0 -translate-y-1/2';

  const rootStyle = {
    width: `${props.renderWidth}px`,
    height: `${props.renderHeight}px`,
    transform:
      'scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))',
    '--tw-scale-x': ratio,
    '--tw-scale-y': ratio
  };

  emit('change', ratio);

  return {
    rootClass,
    rootStyle
  };
});
</script>
