/* 外部方法 */
import { createPinia } from 'pinia';

/* 內部方法 */
import useRootStore from './modules/root';
import useModalStore from './modules/modal';
import useDeskStore from './modules/desk';
import useNewsStore from '@sms/common/store/news';
import useAiStore from './modules/ai';
import usePeerStore from './modules/peer';
import useFlagMapStore from '@sms/common/store/flagMap';
import useSocketStore from '@sms/common/store/socket';
import useStreamStore from './modules/stream';

/* 型別 */
import type { DealerListenEvents, DealerInvokeEvents } from '@/api/socket';

export const pinia = createPinia();

export default {
  useRootStore,
  useModalStore,
  useDeskStore,
  useNewsStore,
  useAiStore,
  usePeerStore,
  useFlagMapStore,
  useStreamStore,
  useSocketStore: useSocketStore<DealerListenEvents, DealerInvokeEvents>()
};
