import { createApp } from 'vue';
import { pinia } from '@/store';

import router from './router';
import App from './App.vue';
import SLoading from '@sms/components/plugins/SLoading';

import '@sms/common/declarations/parseJSON';
import 'floating-vue/dist/style.css';

createApp(App).use(pinia).use(router).use(SLoading).mount('#app');

console.log(`${import.meta.env.VITE_BUILD_DATE}\n${import.meta.env.VITE_BUILD_VERSION}\n${import.meta.env.MODE}`);
