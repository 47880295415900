<template>
  <div class="h-full flex flex-col items-center justify-center bg-gray-800">
    <div
      class="leading-[50px] text-4xl font-normal mb-8 bg-gradient-to-b from-indigo-400 to-sky-200 bg-clip-text"
      style="-webkit-text-fill-color: transparent"
    >
      {{ t('Dealer.Login_TableId') }}
    </div>

    <div class="max-w-xl w-full px-16 py-12 rounded-2xl bg-white">
      <form @submit.prevent="submit">
        <div>
          <div
            :class="[
              'flex bg-[#EDEEEF] rounded-[5px] border',
              formErrors.tableId ? 'border-red-500' : 'border-[#e2e2e2]'
            ]"
          >
            <div class="w-[54px] flex items-center justify-center">
              <SIcon :icon="mdiQrcodeScan" class="w-6 h-6 text-zinc-500" />
            </div>

            <div class="w-px bg-[#e2e2e2] my-[10px]"></div>

            <div class="flex-1 rounded-r-md">
              <input
                id="pbId"
                v-model="form.tableId"
                type="text"
                :class="[
                  'w-full rounded-r-[5px] text-sm h-12 bg-[#EDEEEF] px-3 py-2 border-none outline-none focus:border-indigo-500 focus:ring-indigo-500 focus:ring-2',
                  formErrors.tableId ? 'text-red-500' : 'text-gray-600'
                ]"
                :placeholder="t('Dealer.Login_TableId')"
                :disabled="!(isLocal || isQA) || loading.visible.value"
                @blur="validate"
                @touchcancel="validate"
                @touchend="validate"
              />
            </div>
          </div>
        </div>

        <SBtn type="submit" class="w-full !h-[50px] bg-indigo-500 mt-5">
          {{ t('Common.Login') }}
        </SBtn>

        <div v-if="formErrors.tableId" class="leading-4 text-sm text-red-500 text-center mt-6">
          <span>{{ formErrors.tableId }}</span>
        </div>
      </form>
    </div>
  </div>

  <div class="absolute top-0 right-0 p-6 flex">
    <button
      type="button"
      class="relative inline-flex items-center px-4 py-2 disabled:opacity-40 rounded-md border border-gray-300 bg-red-400 text-sm font-medium text-white hover:bg-red-500 hover:text-red-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500"
      @click="requestHidDevice"
    >
      {{ t('Dealer.Login_BindScanner', { count: hidScanners.length }) }}
    </button>
  </div>
</template>

<script lang="ts" setup>
/* 外部方法 */
import { ref, reactive, computed, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { yupObject, string } from '@sms/common/plugins/yup';
import { ValidationError } from 'yup';

/* 內部方法 */
import { isLocal, isQA } from '@sms/common/utils/helper';
import store from '@/store';
import useI18n from '@sms/common/composables/useI18n';
import useLoading from '@sms/common/composables/useLoading';

/* 外部組件 */
import { mdiQrcodeScan } from '@mdi/js';

/* 內部組件 */
import useScannerParser from '@sms/common/composables/useScannerParser';
import SIcon from '@sms/components/SIcon/SIcon.vue';
import SBtn from '@sms/components/SBtn/SBtn.vue';

/* API */
import authService from '@/api/ajax/authService';

/** 型別 */
import CustomError from '@sms/common/models/CustomError';

/** 系統元件 */
const rootStore = store.useRootStore();
const router = useRouter();
const { t } = useI18n();
const loading = useLoading();

/** 共用變數 */
const hasCamera = ref(false); // 是否有鏡頭

/** 表單相關 */
const form = reactive({
  tableId: ''
});

const formErrors = reactive({
  tableId: ''
});

/* yup驗證方法 */
const schema = computed(() =>
  yupObject({
    tableId: string().uuid(t('Error.Input_Valid_UUID')).required(t('Error.Input_Valid_Required'))
  })
);

const validate = () => {
  try {
    // 重置錯誤訊息
    formErrors.tableId = '';

    schema.value.validateSync({ tableId: form.tableId }, { abortEarly: false });

    return true;
  } catch (error) {
    // 顯示 yup 驗證訊息 (多驗證規則下也只顯示一個)
    if (error instanceof ValidationError) {
      const keys = Object.keys(formErrors);

      error.inner.forEach((x) => {
        if (x.path && keys.includes(x.path)) {
          const key = x.path as keyof typeof formErrors;
          formErrors[key] = x.message;
        }
      });
    }

    return false;
  }
};

const submit = async () => {
  try {
    const isValid = validate();
    if (!isValid) return;

    loading.show();

    const resp = await authService.login(rootStore.currentPbId, form.tableId);

    rootStore.setToken(resp.data.Data.token);
    router.replace({ name: 'Desk', params: { deskId: form.tableId } });
  } catch (error) {
    if (error instanceof CustomError) {
      formErrors.tableId = error.message;
    }
  } finally {
    loading.hide();
  }
};

/** 掃描相關 */
const { hidScanners, requestHidDevice, scannerEvent } = useScannerParser();

const scanHandler = (str: string) => {
  form.tableId = str;
  submit();
};

scannerEvent.on('LOCATION', scanHandler);
onBeforeUnmount(() => scannerEvent.off('LOCATION', scanHandler));

(async () => {
  const mediaDeviceList = await navigator.mediaDevices.enumerateDevices();
  hasCamera.value = !!mediaDeviceList.find((x) => x.kind === 'videoinput');
  if (import.meta.env.DEV) {
    // 此ID為locationId
    form.tableId = 'f3200552-1dcf-40d3-afbc-0f3f7944b606';
  }
})();
</script>
