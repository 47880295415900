<template>
  <div class="h-full w-full flex flex-col">
    <div class="w-full flex-1 min-h-0">
      <slot />
    </div>
    <div class="h-7 w-full">
      <slot name="status-bar" />
    </div>
  </div>
</template>
